import s from './Invite.module.sass';

import { FC, useEffect, useState } from 'react';
import { Button, Placeholder } from '@vkontakte/vkui';
import { InlineHeader } from '$uikit';
import { useCurrentUser, useTranslator } from '$hooks';
import clipboardCopy from 'clipboard-copy';
import { useDispatch } from 'react-redux';
import { toastActions } from '$store/toast';
import { Api } from '$api';
import { Loader } from '$shared/components';
import { Icon56ErrorOutline } from '@vkontakte/icons';
import WebApp from '@twa-dev/sdk';
import { statReachGoal } from '$utils';

export const Invite: FC = () => {
  const t = useTranslator();
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const [loadingStatus, setLoadingStatus] = useState('loading');
  const [data, setData] = useState({
    balance: 0,
    reward: 0,
  });

  const loadData = () => {
    setLoadingStatus('loading');
    Api.get<any, any>('/invite')
      .then((resp) => {
        setData(resp);
        setLoadingStatus('');
      })
      .catch((e) => {
        setLoadingStatus('failed');
        dispatch(toastActions.setToastFail(e.message));
      });
  };

  useEffect(() => {
    statReachGoal('invite_open');
    loadData();
  }, []);

  const link = `https://t.me/nzlchatbot?start=ref_${user?.id ?? 0}`;
  const handleCopy = () => {
    clipboardCopy(link);
    dispatch(toastActions.setToastSuccess('Ссылка скопирована!'));
    statReachGoal('invite_link_copy');
  };

  const handleWallet = () => {
    WebApp.openTelegramLink('https://t.me/wallet');
  };

  const handleTon = () => {
    WebApp.openLink('https://ton.org');
  };

  function renderContent() {
    if (loadingStatus === 'loading') {
      return <Loader isWrapped />;
    }

    if (loadingStatus === 'failed') {
      return (
        <Placeholder
          icon={<Icon56ErrorOutline />}
          stretched
          header={t('error_title')}
          action={<Button onClick={loadData}>{t('error_button')}</Button>}
        >
          {t('error_text')}
        </Placeholder>
      );
    }

    return (
      <>
        <div className={s.balanceWrap}>
          <div className={s.balance}>
            <div className={s.balanceLabel}>Ваш баланс:</div>
            <div className={s.balanceAmount}>{data.balance} монет</div>
          </div>
          <div className={s.balanceHint}>1 монета = 1 рубль</div>
        </div>
        <InlineHeader>Получить монеты</InlineHeader>
        <div className={s.inviteWrap}>
          <div>
            Вы получите <b>{data.reward} монет</b> за каждого человека, который создаст
            анкету по вашей пригласительной ссылке!
          </div>
          <div className={s.inviteLink} onClick={handleCopy}>
            <div className={s.inviteLinkLabel}>Ваша ссылка:</div>
            <div className={s.inviteLinkValue}>{link}</div>
            <div className={s.inviteLinkCopy}>
              <Button>Скопировать</Button>
            </div>
          </div>
        </div>
        <InlineHeader>Вывести монеты</InlineHeader>
        <div className={s.withdrawWrap}>
          <div className={s.withdraw}>
            Вывод монет происходит автоматически в первый день каждого месяца на ваш
            аккаунт в{' '}
            <span onClick={handleWallet}>оффициальном кошельке от Telegram</span>.
            <br />
            <br />
            Вывод происходит при условии, что у вас есть не менее 100 монет.
            <br />
            <br />1 монета равняется 1 рублю, во время вывода мы конвертируем монеты в{' '}
            <span onClick={handleTon}>криптовалюту TON</span> и отправляем на ваш кошелек,
            далее вы можете обменять их на рубли.
          </div>
        </div>
      </>
    );
  }

  return <>{renderContent()}</>;
};
