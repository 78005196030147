import UAParser from 'ua-parser-js';

import { t } from '$translation/helper';
import bridge, { BannerAdLayoutType, BannerAdLocation } from '@vkontakte/vk-bridge';
import * as amplitude from '@amplitude/analytics-browser';
const uaParser = new UAParser();

export function smartFeedEnabled(userId: number, contentPriority): boolean {
  if (contentPriority === 'adult') {
    return false;
  }

  if ([1, 39469, 3, 7, 136476, 156525, 87344].indexOf(userId) > -1) {
    return true;
  }

  return true; //userId % 2 === 0;
}

export const chunk = (input: any[], size: number) => {
  return input.reduce((arr, item, idx) => {
    return idx % size === 0
      ? [...arr, [item]]
      : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
  }, []);
};

export function shuffle(a: any[]) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export function isAdminPanel(): boolean {
  // @ts-ignore
  return !!window.adminPanel;
}

export function capitalize(str: string) {
  return str.substring(0, 1).toUpperCase() + str.substring(1);
}

export function genderText(gender: number, variants: string[]) {
  // gender 2 is female
  return +gender === 2 ? variants[1] : variants[0];
}

export function classNames(...args) {
  let result: any[] = [];

  // @ts-ignore
  [].concat(args).forEach(function (item) {
    if (!item) {
      return;
    }
    switch (typeof item === 'undefined' ? 'undefined' : typeof item) {
      case 'string':
        result.push(item);
        break;
      case 'object':
        Object.keys(item).forEach(function (key) {
          if (item[key]) {
            result.push(key);
          }
        });
        break;
      default:
        result.push('' + item);
    }
  });

  return result.join(' ');
}

export function ily(yr) {
  return !(yr % 4 || (!(yr % 100) && yr % 400));
}

export function roundNumber(input, decimals = 2) {
  const roundTo = Math.pow(10, decimals);
  return Math.round(input * roundTo) / roundTo;
}

export function roundCryptoNumber(input, decimals = 2) {
  return input > 1
    ? roundNumber(input, decimals)
    : eval(input)
        .toPrecision(decimals)
        .replace(/(?:\.0+|(\.\d+?)0+)$/, '$1');
}

export function formatBigNumber(input) {
  if (input < 1000) {
    return input;
  } else if (input < 1000000) {
    return `${roundNumber(input / 1000, 1)}K`;
  } else {
    return `${roundNumber(input / 1000000, 1)}M`;
  }
}

export function gram(number, variants, skipNumber = false): string {
  const cases = [2, 0, 1, 1, 1, 2];
  let res =
    variants[
      number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
    ];
  if (!skipNumber) {
    res = number + ' ' + res;
  }
  return res;
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePhone(phone) {
  return `${phone}`.length >= 8;
}

export function validatePassword(password) {
  return `${password}`.length >= 8;
}

export function canvasToBlob(canvas, quality = 1): Promise<Blob> {
  return new Promise((resolve) => {
    canvas.toBlob((blob) => resolve(blob), 'image/jpeg', quality);
  });
}

export const imageLoad = (url: string) =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.src = url;
  });

export function scrollToBottom() {
  const el = document.getElementById('ScrollView') as HTMLElement;
  el.scrollTo({
    top: el.scrollHeight - el.offsetHeight,
  });
}

export function isDesktop() {
  return window.innerWidth >= 1000;
}

interface StatQueueItem {
  name: string;
  params: any;
}
let StatsQueue: StatQueueItem[] = [];
export function statReachGoal(eventName: string, params: any = {}) {
  try {
    if (!window.isVK) {
      amplitude.track(eventName, params, {
        platform: window.isVK ? 'vk' : 'telegram',
      });
    }
    statYandex(eventName, params);
  } catch (e) {}
}

function statYandex(eventName: string, params: any = {}) {
  try {
    // @ts-ignore
    if (window.ym) {
      // @ts-ignore
      window.ym(94742750, 'setUserID', String(window.userID ?? 0));
      // @ts-ignore
      window.ym(94742750, 'reachGoal', eventName, params);

      if (StatsQueue.length > 0) {
        for (let event of StatsQueue) {
          // @ts-ignore
          window.ym(94742750, 'reachGoal', event.name, event.params);
        }
        StatsQueue = [];
      }
    } else {
      StatsQueue.push({ name: eventName, params });
    }
  } catch (e) {
    //
  }
}

export function isPWA() {
  return isIOS()
    ? window.navigator['standalone'] === true
    : uaParser.getDevice().type === 'mobile' &&
        window.matchMedia('(display-mode: fullscreen)').matches;
}

export function isIOS() {
  if (window.isVK) {
    return (
      window.vkPlatform === 'mobile_iphone' ||
      window.vkPlatform === 'mobile_ipad' ||
      window.vkPlatform === 'mobile_iphone_messenger'
    );
  }
  return uaParser.getOS().name === 'iOS';
}

export function canInstallPWA() {
  return !isSafari() && !isIOS();
}

export function isSafari() {
  return uaParser.getBrowser().name === 'Safari';
}

export function internalLink(link: string) {
  return !!link.match(/^(https?:\/\/)?(ton)\.(place)/i);
}

export function findScam(link: string) {
  return !!link.match(/rocketon/i);
}

export function handleScam(e) {
  if (confirm(t('link_scam'))) {
    return true;
  }

  try {
    e.stopPropagation();
    e.preventDefault();
  } catch (e) {}
  return false;
}
// @ts-ignore
window.handleScam = handleScam;

export function formatFileSize(fileSizeInBytes: number) {
  const i =
    fileSizeInBytes === 0 ? 0 : Math.floor(Math.log(fileSizeInBytes) / Math.log(1024));
  return (
    Number((fileSizeInBytes / Math.pow(1024, i)).toFixed(2)) +
      ' ' +
      ['B', 'KB', 'MB', 'GB'][i] || 'GB'
  );
}

function formatLocalTime(time: number) {
  return time < 10 ? `0${time}` : time;
}

export function getFormattedTime(timeInSeconds: number) {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = Math.floor(timeInSeconds % 60);

  return (
    (hours > 1 ? `${formatLocalTime(hours)}:` : '') +
    `${formatLocalTime(minutes)}:${formatLocalTime(seconds)}`
  );
}

export function transformUserDistance(distance) {
  if (!distance) {
    return null;
  }

  const isKilometers = distance >= 1000;
  const transformedDistance = Math.floor(isKilometers ? distance / 1000 : distance);
  const unit = isKilometers ? 'км' : 'м';

  return isKilometers
    ? `${transformedDistance} ${unit} от Вас`
    : 'меньше километра от Вас';
}

export function getDistanceBetweenTwoElements(
  leftElement: HTMLElement,
  rightElement: HTMLElement,
) {
  const leftRect = leftElement.getBoundingClientRect();
  const rightRect = rightElement.getBoundingClientRect();

  return rightRect.left - leftRect.right;
}

export function cutNumberFractionalPart(num: number, fractionalLength = 2) {
  const [integer, fractional] = num.toString().split('.');

  if (!fractional || fractional.length <= 1) {
    return num;
  }

  return `${integer}.${fractional.slice(
    0,
    fractional[fractionalLength - 1] === '0' ? fractionalLength - 1 : fractionalLength,
  )}`;
}

export const toPrecisionWithoutRounding = (
  num: number,
  precise?: number,
  maximumFractionDigits: number = 20,
): string => {
  const stringifiedNumber = num.toLocaleString('en-US', {
    useGrouping: false,
    maximumFractionDigits,
  });

  if (!precise || Number.isInteger(num)) {
    return stringifiedNumber;
  }

  const preciseRegExp = new RegExp(`\\d+(.0*[1-9]{1,${precise}})?`);

  const matchedRegExpResult = stringifiedNumber.match(preciseRegExp);

  return matchedRegExpResult ? matchedRegExpResult[0] : '0';
};

export const isTesterId = (id: number) => {
  return [
    1, // Andrew Goglev
    8, // Denis Borodin
    178133, // Denis Denis
    163935, // adonis vernalis
    156525, // Юлия Кусь
    179265, // Jules Vaughn
    172708, // Hey Man
    136476, // Scary Stories
    170903, // Dogina Mil
    165195, // Nikola Fet
    152482, // Mark Erov
  ].includes(id);
};

export const getCaptchaToken: () => Promise<string> = () =>
  new Promise((resolve, reject) => {
    dispatchEvent(new CustomEvent('showCaptcha'));
    const successCallback = (e) => {
      const { token } = e.detail;
      if (token) {
        resolve(token);
      } else {
        reject();
      }
      removeEventListener('captchaChange', successCallback);
    };
    const rejectCallback = (e) => {
      reject();
      removeEventListener('captchaReject', successCallback);
    };
    addEventListener('captchaChange', successCallback);
    addEventListener('captchaReject', rejectCallback);
  });

export const getAuthorization = () => {
  if (window.isVK) {
    return `VkAuth ${window.initUrl}`;
  } else {
    return `TgAuth ${getToken()}`;
  }
};

export const getToken = () => {
  const token = window.localStorage.getItem('dateToken');
  if (token) {
    return token;
  }

  const data = Telegram.WebApp.initDataUnsafe as any;
  const tgData = Object.keys(data)
    .filter((key) => key !== 'hash')
    .sort()
    .map((k) => `${k}=${typeof data[k] === 'object' ? JSON.stringify(data[k]) : data[k]}`)
    .join('\n');

  return btoa([123, unescape(encodeURIComponent(tgData)), data.hash].join(';'));
};

export function retry(fn, retriesLeft = 5, interval = 1000): Promise<any> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export function delay(timeout): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
}

export const getColor = (color: string) => {
  return window
    .getComputedStyle(document.querySelector('#root')!)
    .getPropertyValue(`--${color}`);
};

export function getLookingForSmile(value: number) {
  if (value === 1) {
    return '💘';
  } else if (value === 2) {
    return '😍';
  } else if (value === 3) {
    return '🥂';
  } else if (value === 4) {
    return '🎉';
  } else if (value === 5) {
    return '👋';
  } else if (value === 6) {
    return '🤔';
  }
}

export function getLookingForLabel(value: number) {
  if (value === 1) {
    return 'edit_looking_for_long_term';
  } else if (value === 2) {
    return 'edit_looking_for_long_term_short';
  } else if (value === 3) {
    return 'edit_looking_for_short_term_long';
  } else if (value === 4) {
    return 'edit_looking_for_short_term';
  } else if (value === 5) {
    return 'edit_looking_for_friends';
  } else if (value === 6) {
    return 'edit_looking_for_not_sure';
  } else {
    return '';
  }
}

export function showBannerAds() {
  if (!window.isVK || window.hasPremium) {
    return;
  }

  bridge
    .send('VKWebAppShowBannerAd', {
      banner_location: BannerAdLocation.BOTTOM,
      layout_type: BannerAdLayoutType.RESIZE,
      can_close: true,
    })
    .then((data) => {
      if (data.result) {
        statReachGoal('show_banner_ads');
      }
    })
    .catch((error) => {
      // Ошибка
      console.log(error);
    });
}

export * from './date';
