import { all, put, takeLatest, call, delay } from 'redux-saga/effects';
import { mainActions } from '$store/main/index';
import { Api } from '$api';
import { batchActions } from '$store';
import { isIOS, showBannerAds, statReachGoal } from '$utils';
import router from '$navigation/router';
import { JOIN, PREMIUM } from '$shared/constants/pages';

function* loadHomeWorker() {
  try {
    const data = yield call(Api.get, '/home');
    yield put(
      batchActions(
        mainActions.setUsers(data.users),
        mainActions.setHome({
          likes: data.likes,
          matches: data.matches,
          likesCount: data.likesCount,
          matchesCount: data.matchesCount,
          needCreateProfile: data.needCreateProfile,
        }),
      ),
    );
  } catch (e) {
    yield delay(3000);
    yield put(mainActions.loadHome());
  }
}

function* initWorker() {
  try {
    const data = yield call(Api.get, '/init');
    yield put(
      batchActions(
        mainActions.setActiveTab(window.defaultTab),
        mainActions.setUsers({
          [data.user.id]: data.user,
        }),
        mainActions.setUserId(data.user.id),
        data.created && mainActions.setCreated(),
        mainActions.setPremiumEnabled(data.premiumEnabled && (!window.isVK || !isIOS())),
        mainActions.setHasPremium(data.hasPremium),
        mainActions.setPremiumPrice(data.premiumPrice),
        mainActions.setLikesBadgeShown(data.likesBadgeShown),
        mainActions.setTrialAvailable(data.isTrialAvailable),
        mainActions.setPremiumRequested(!!data.premiumRequested),
      ),
    );

    window.userID = data.user.id;

    if (data.created) {
      showBannerAds();
      statReachGoal('user_visit');

      if (data.hasPremium) {
        statReachGoal('premium_user_visit');
      } else {
        statReachGoal('free_user_visit');
      }

      if (window.startAction) {
        if (window.startAction === 'premium') {
          router.navigate(PREMIUM);
        } else if (window.startAction === 'premiumEmail') {
          router.navigate(PREMIUM, {
            step: 'email',
          });
        }
        window.startAction = '';
      }
    } else {
      statReachGoal('guest_visit');
      yield call(router.navigate, JOIN);
    }
  } catch (e) {
    yield delay(3000);
    yield put(mainActions.init());
  }
}

export function* mainSaga() {
  yield all([
    takeLatest(mainActions.loadHome, loadHomeWorker),
    takeLatest(mainActions.init, initWorker),
  ]);
}
