import s from './Join.module.sass';
import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  FormItem,
  FormLayout,
  Input,
  PanelHeader,
  PanelHeaderBack,
} from '@vkontakte/vkui';
import { goBack } from '$navigation/router';
import cn from 'classnames';
import { MainButton, ScrollView } from '$uikit';
import { toastActions } from '$store/toast';
import { useDispatch } from 'react-redux';
import { differenceInYears, format, isFuture, isValid } from 'date-fns';
import { parse, showBannerAds, statReachGoal } from '$utils';
import { PhotoModel } from '$store/models';
import { Loader, PhotosEdit } from '$shared/components';
import { Api } from '$api';
import { batchActions } from '$store';
import { mainActions } from '$store/main';
import { useTranslator } from '$hooks';
import bridge from '@vkontakte/vk-bridge';
import WebApp from '@twa-dev/sdk';

function ProgressBar({ step, title, caption = null }: any) {
  const progress = (step / 4) * 100;

  return (
    <div className={s.progressBarWrap}>
      <div className={s.progressBar}>
        <div className={s.progressBarIndicator} style={{ width: `${progress}%` }} />
      </div>
      <div className={s.progressBarTitle}>{title}</div>
      {caption && <div className={s.progressBarCaption}>{caption}</div>}
    </div>
  );
}

export const Join: FC = () => {
  const t = useTranslator();
  const dispatch = useDispatch();
  const [step, setStep] = React.useState(0);
  const [sex, setSex] = useState(0);
  const [name, setName] = useState(window.initUserData?.name || '');
  const [age, setAge] = useState(0);
  const [photos, setPhotos] = useState<PhotoModel[]>([]);
  const [isSaving, setSaving] = useState(false);
  const [isFetchVKInfo, setFetchVKInfo] = useState(window.isVK);
  const [vkPhoto, setVkPhoto] = useState('');

  useEffect(() => {
    statReachGoal('join_page');

    if (window.isVK) {
      bridge
        .send('VKWebAppGetUserInfo')
        .then((data) => {
          if (data.sex > 0) {
            setSex(data.sex);
            setStep(1);
          }

          if (data.first_name) {
            setName(data.first_name);
          }

          if (data.bdate) {
            const exp = data.bdate.split('.');
            if (exp.length > 2) {
              const date = parse(data.bdate, 'd.M.yyyy', new Date());
              setAge(differenceInYears(new Date(), date));
            }
          }

          if (data.photo_max_orig && data.photo_max_orig.indexOf('camera_') === -1) {
            setVkPhoto(data.photo_max_orig);
          }

          setFetchVKInfo(false);
        })
        .catch(() => {
          setFetchVKInfo(false);
        });
    } else {
      if (WebApp.initDataUnsafe && WebApp.initDataUnsafe.user) {
        if (
          WebApp.initDataUnsafe.user.photo_url &&
          WebApp.initDataUnsafe.user.photo_url.indexOf('jpeg') > -1
        ) {
          setVkPhoto(WebApp.initDataUnsafe.user.photo_url);
        }

        if (WebApp.initDataUnsafe.user.first_name) {
          setName(WebApp.initDataUnsafe.user.first_name);
        }
      }
    }
  }, []);

  useEffect(() => {
    statReachGoal('join_page_step_' + step);
  }, [step]);

  const handleSex = (newSex: number) => {
    setSex(newSex);
    setStep(step + 1);
  };

  const handleName = () => {
    const nameParsed = name.trim();
    if (nameParsed.length < 2) {
      dispatch(toastActions.setToastFail(t('join_name_short')));
      return;
    }

    let incr = 1;
    if (age > 0) {
      statReachGoal('join_page_step_' + (step + 1));
      incr++;
    }
    setStep(step + incr);
  };

  const handleBDate = () => {
    const currentYear = new Date().getFullYear();
    const birthYear = currentYear - age;
    const date = new Date(birthYear, 0, 1);

    if (!isValid(date)) {
      dispatch(toastActions.setToastFail(t('join_born_incorrect')));
      return;
    }

    if (isFuture(date)) {
      dispatch(toastActions.setToastFail(t('join_born_err_future')));
      return;
    }

    const yearDifference = differenceInYears(new Date(), date);
    const isValidAge = yearDifference >= 16 && yearDifference <= 80;

    if (!isValidAge) {
      dispatch(toastActions.setToastFail(t('join_born_err_out_of_range')));
      return;
    }

    setStep(step + 1);
  };

  const handleSave = () => {
    if (!photos.length) {
      dispatch(toastActions.setToastFail(t('join_photos_empty')));
      return;
    }

    const currentYear = new Date().getFullYear();
    const birthYear = currentYear - age;
    const date = new Date(birthYear, 0, 1);

    setSaving(true);
    Api.post<any, any>('/account/edit', {
      name: name.trim(),
      bio: '',
      sex: +sex,
      bDate: format(date, 'yyyy-MM-dd'),
      photos: photos.map((p) => p.id),
      lookingFor: 0,
    })
      .then((data) => {
        setSaving(false);
        dispatch(
          batchActions(
            mainActions.setUser(data.user),
            toastActions.hideToast(),
            mainActions.setNeedCreateProfileFalse(),
            mainActions.setCreated(),
          ),
        );
        goBack();

        showBannerAds();
        statReachGoal('user_visit');
        statReachGoal('registration');
      })
      .catch((err) => {
        setSaving(false);
        dispatch(toastActions.setToastFail(err.message));
      });
  };

  function renderStep() {
    if (isFetchVKInfo) {
      return <Loader isWrapped />;
    }

    if (step === 0) {
      return (
        <>
          <ProgressBar
            step={step + 1}
            title={t('join_name_title')}
            caption={t('join_name_caption')}
          />
          <FormLayout>
            <FormItem>
              <Input
                autoFocus
                placeholder={t('join_name_placeholder')}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormItem>
            {window.isVK ? (
              <FormItem>
                <Button onClick={handleName} size="l" stretched>
                  {t('join_continue')}
                </Button>
              </FormItem>
            ) : (
              <MainButton onClick={handleName} text={t('join_continue')} />
            )}
          </FormLayout>
        </>
      );
    } else if (step === 1) {
      return (
        <>
          <ProgressBar step={step + 1} title={t('join_sex_title')} />
          <div className={s.genderWrap}>
            <div className={s.gender} onClick={() => handleSex(1)}>
              <div
                className={cn({
                  [s.genderIcon]: true,
                  [s.genderWoman]: true,
                })}
              />
              <div className={s.genderText}>{t('join_sex_woman')}</div>
            </div>
            <div className={s.gender} onClick={() => handleSex(2)}>
              <div
                className={cn({
                  [s.genderIcon]: true,
                  [s.genderMan]: true,
                })}
              />
              <div className={s.genderText}>{t('join_sex_man')}</div>
            </div>
          </div>
        </>
      );
    } else if (step === 2) {
      return (
        <>
          <ProgressBar
            step={step + 1}
            title={t('join_born_title')}
            caption={t('join_born_caption')}
          />
          <FormLayout>
            <FormItem>
              <Input
                placeholder={t('join_born_placeholder')}
                value={age > 0 ? age + '' : ''}
                onChange={(e) => setAge(parseInt(e.target.value, 10) || 0)}
              />
            </FormItem>
            {window.isVK ? (
              <FormItem>
                <Button onClick={handleBDate} size="l" stretched>
                  {t('join_continue')}
                </Button>
              </FormItem>
            ) : (
              <MainButton onClick={handleBDate} text={t('join_continue')} />
            )}
          </FormLayout>
        </>
      );
    } else if (step === 3) {
      return (
        <>
          <ProgressBar
            step={step + 1}
            title={t('join_photos_title')}
            caption={t('join_photos_caption')}
          />
          <FormLayout>
            <FormItem>
              <PhotosEdit photos={photos} setPhotos={setPhotos} prefetchPhoto={vkPhoto} />
            </FormItem>
            <MainButton onClick={handleSave} text={t('join_done')} progress={isSaving} />
          </FormLayout>
        </>
      );
    }
  }

  return (
    <div className={s.wrap}>
      {!window.isVK && step > 0 && (
        <PanelHeader
          before={
            <PanelHeaderBack
              onClick={() => {
                setStep((prev) => prev - 1);
              }}
            />
          }
        />
      )}
      <ScrollView>{renderStep()}</ScrollView>
    </div>
  );
};
