import s from './PremiumBanner.module.sass';
import { FC } from 'react';
import { IcChevron16, IcStar28 } from '$assets';
import { useRouter } from 'react-router5';
import { FILTERS, FRIENDS, PREMIUM } from '$shared/constants/pages';
import { useSelector } from 'react-redux';
import { likesSelector } from '$store/likes';
import { useTranslator } from '$hooks';
import { classNames, isIOS } from '$utils';
import { mainSelector } from '$store/main';

export const PremiumBanner: FC = () => {
  const t = useTranslator();
  const router = useRouter();
  const { premiumEnabled } = useSelector(mainSelector);
  const { likes } = useSelector(likesSelector);

  const handlePress = () => {
    router.navigate(PREMIUM);
  };

  if (window.isVK && isIOS()) {
    return null;
  }

  return (
    <div className={s.container}>
      <div className={s.wrap}>
        <div className={s.icon}>
          <IcStar28 />
        </div>
        <div className={s.contWrap}>
          <div className={s.cont}>
            <div className={s.title}>
              {likes.length > 0 && premiumEnabled
                ? t('likes_premium_required_title', {
                    count: likes.length,
                  })
                : t('premium_banner_title')}
            </div>
            <div className={s.caption}>
              {likes.length > 0 && premiumEnabled
                ? t('premium_banner_description_with_likes')
                : t('premium_banner_description')}
            </div>
          </div>
        </div>
        <div className={s.button} onClick={() => router.navigate(FRIENDS)}>
          Премиум бесплатно
        </div>
        <div
          className={classNames({
            [s.button]: true,
            [s.buttonSecondary]: true,
          })}
          onClick={handlePress}
        >
          Купить премиум
        </div>
      </div>
    </div>
  );
};
