import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  MainState,
  MoveLikeAction,
  SetHomeAction,
  SetPopoutAction,
  SetUserAction,
  SetUsersAction,
} from './interface';
import { RootState } from 'store/rootReducer';

const initialState: MainState = {
  userId: 0,
  created: false,
  premiumEnabled: false,
  hasPremium: false,
  popout: null,
  users: {},
  likes: [],
  matches: [],
  likesCount: 0,
  matchesCount: 0,
  loadingStatus: 'loading',
  needCreateProfile: false,
  activeTab: 'cards',
  premiumPrice: 0,
  likesBadgeShown: false,
  isTrialAvailable: false,
  premiumRequested: true,
};

export const { actions, reducer } = createSlice({
  name: 'main',
  initialState,
  reducers: {
    init() {},
    setCreated(state) {
      state.created = true;
    },
    setUserId(state, action: PayloadAction<number>) {
      state.userId = action.payload;
    },
    setActiveTab(state, action: PayloadAction<string>) {
      state.activeTab = action.payload;
    },
    setPopout(state, action: SetPopoutAction) {
      state.popout = action.payload;
    },
    setUsers(state, action: SetUsersAction) {
      for (let userId in action.payload) {
        let user = action.payload[userId];
        state.users[user.id] = user;
      }
    },
    setUser(state, action: SetUserAction) {
      const userId = action.payload.id!;
      state.users[userId] = {
        ...(state.users[userId] || {}),
        ...action.payload,
      };
    },
    setHome(state, action: SetHomeAction) {
      state.likes = action.payload.likes;
      state.matches = action.payload.matches;
      state.likesCount = action.payload.likesCount;
      state.matchesCount = action.payload.matchesCount;
      state.needCreateProfile = action.payload.needCreateProfile;
      state.loadingStatus = '';
    },
    setNeedCreateProfileFalse(state) {
      state.needCreateProfile = false;
    },
    loadHome() {},
    moveLike(state, action: MoveLikeAction) {
      const likesCopy = [...state.likes];
      const likeIndex = likesCopy.findIndex(
        (like) => like.fromId === action.payload.fromId,
      );

      if (likeIndex !== -1) {
        likesCopy.splice(likeIndex, 1);
        state.likes = likesCopy;
        state.likesCount--;
      }

      if (action.payload.reason === 1) {
        state.matchesCount++;
        const matchesCopy = [...state.matches];
        matchesCopy.unshift({
          id: action.payload.fromId,
          fromId: action.payload.fromId,
        });
        state.matches = matchesCopy;
      }
    },
    setHasPremium(state, action: PayloadAction<boolean>) {
      state.hasPremium = action.payload;
      window.hasPremium = action.payload;
    },
    setPremiumEnabled(state, action: PayloadAction<boolean>) {
      state.premiumEnabled = action.payload;
    },
    setPremiumPrice(state, action: PayloadAction<number>) {
      state.premiumPrice = action.payload;
    },
    setLikesBadgeShown(state, action: PayloadAction<boolean>) {
      state.likesBadgeShown = action.payload;
    },
    setTrialAvailable(state, action: PayloadAction<boolean>) {
      state.isTrialAvailable = action.payload;
    },
    setPremiumRequested(state, action: PayloadAction<boolean>) {
      state.premiumRequested = action.payload;
    },
  },
});

export { reducer as mainReducer, actions as mainActions };

export const mainSelector = (state: RootState) => state.main;

export const premiumRequiredSelector = createSelector(
  mainSelector,
  (main) => main.premiumEnabled && !main.hasPremium,
);

export const userSelector = createSelector(
  mainSelector,
  (_, userId: number) => userId,
  (main, userId) => {
    return main.users[userId];
  },
);

export const userIdSelector = createSelector(mainSelector, (main) => {
  return main.userId;
});

export const createdSelector = createSelector(mainSelector, (main) => {
  return main.created;
});
