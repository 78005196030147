import s from './Premium.module.sass';
import { FC, useEffect, useRef, useState } from 'react';
import {
  Button,
  FormItem,
  FormLayout,
  Input,
  PanelHeader,
  PanelHeaderBack,
  Placeholder,
} from '@vkontakte/vkui';
import { goBack } from '$navigation/router';
import { MainButton, ScrollView } from '$uikit';
import { toastActions } from '$store/toast';
import { Api } from '$api';
import WebApp from '@twa-dev/sdk';
import { useDispatch, useSelector } from 'react-redux';
import { IcStar28 } from '$assets';
import { mainActions, mainSelector } from '$store/main';
import { useTranslator } from '$hooks';
import { statReachGoal, validateEmail } from '$utils';
import { useRoute, useRouter } from 'react-router5';
import bridge from '@vkontakte/vk-bridge';
import { LottieIcon } from '$shared/components';
import { FRIENDS } from '$shared/constants/pages';

export const Premium: FC = () => {
  const { route } = useRoute();
  const defaultStep = route.params?.step || null;

  const router = useRouter();
  const t = useTranslator();
  const dispatch = useDispatch();
  const timer = useRef<any>(0);
  const { hasPremium, premiumPrice, isTrialAvailable, premiumEnabled } =
    useSelector(mainSelector);
  const [step, setStep] = useState(defaultStep === 'email' ? 'email' : 'preview');
  const [email, setEmail] = useState('');

  useEffect(() => {
    dispatch(mainActions.setPremiumRequested(true));
  }, []);

  useEffect(() => {
    if (step === 'preview') {
      statReachGoal('premium_show');
    }
  }, [step]);

  function check() {
    Api.get<any, any>('/kassa/pay/check')
      .then((data) => {
        if (data.hasPremium) {
          dispatch(toastActions.setToastSuccess('Премиум активирован!'));
          dispatch(mainActions.setHasPremium(true));
          goBack();
        } else {
          timer.current = setTimeout(() => check(), 3000);
        }
      })
      .catch(() => {
        timer.current = setTimeout(() => check(), 3000);
      });
  }

  useEffect(() => {
    if (hasPremium) {
      return;
    }

    timer.current = setTimeout(() => check(), 3000);
    return () => {
      clearTimeout(timer.current);
    };
  }, [hasPremium]);

  const handleEmail = () => {
    if (window.isVK) {
      bridge
        .send('VKWebAppShowSubscriptionBox', {
          action: 'create',
          item: 'dating_subscription_month',
        })
        .then((data) => {
          dispatch(toastActions.setToastSuccess());
        })
        .catch((e) => {
          console.log('Ошибка!', e);
        });
    } else {
      setStep('email');
      statReachGoal('premium_activate_press');
    }
  };

  const handleBuy = () => {
    let emailPrepared = email.trim();
    if (emailPrepared.length > 0) {
      if (!validateEmail(emailPrepared)) {
        dispatch(toastActions.setToastFail('Некорректный email'));
        return;
      }
    } else {
      emailPrepared = 'goglev-andrey@mail.ru';
    }

    statReachGoal('premium_buy_press');
    dispatch(toastActions.setToastLoading());
    Api.post<any, any>('/kassa/pay/link', {
      email: emailPrepared,
    })
      .then((data) => {
        if (data.hasPremium) {
          dispatch(mainActions.setHasPremium(true));
          goBack();
          dispatch(toastActions.setToastSuccess('Премиум активирован!'));
        } else {
          WebApp.openLink(data.url);
          dispatch(toastActions.hideToast());
          // setTimeout(() => {
          //   dispatch(toastActions.hideToast());
          //   WebApp.openTelegramLink(data.url);
          // }, 1000);
        }
      })
      .catch((e) => {
        dispatch(toastActions.setToastFail(e.message));
      });
  };

  function getButtonText() {
    if (window.isVK) {
      if (isTrialAvailable) {
        return 'Активировать бесплатно';
      } else {
        return 'Активировать';
      }
    } else {
      if (isTrialAvailable) {
        return 'Получить на 3 дня за 1₽';
      } else {
        return `Активировать`;
      }
    }
  }

  function renderContent() {
    if (step === 'preview') {
      return (
        <>
          <Placeholder
            icon={<LottieIcon loop name="paywall" />}
            header={t('premium_title')}
            action={
              <Button onClick={() => router.navigate(FRIENDS)}>Получить бесплатно</Button>
            }
          >
            {t('premium_caption')}
          </Placeholder>
          <div className={s.rows}>
            <div className={s.rows_in}>
              <div className={s.row}>
                <div className={s.rowTitle}>{t('premium_top_title')}</div>
                <div className={s.rowCaption}>{t('premium_top_text')}</div>
              </div>
              {premiumEnabled && (
                <div className={s.row}>
                  <div className={s.rowTitle}>{t('premium_likes_title')}</div>
                  <div className={s.rowCaption}>{t('premium_likes_text')}</div>
                </div>
              )}
              <div className={s.row}>
                <div className={s.rowTitle}>{t('premium_priority_title')}</div>
                <div className={s.rowCaption}>{t('premium_priority_text')}</div>
              </div>
              <div className={s.row}>
                <div className={s.rowTitle}>{t('premium_unlimited_likes_title')}</div>
                <div className={s.rowCaption}>{t('premium_unlimited_likes_text')}</div>
              </div>
              <div className={s.row}>
                <div className={s.rowTitle}>{t('premium_ads_title')}</div>
                <div className={s.rowCaption}>{t('premium_ads_text')}</div>
              </div>
            </div>
          </div>
          <MainButton style="confirm" onClick={handleEmail} text={getButtonText()} />
        </>
      );
    } else if (step === 'email') {
      return (
        <>
          <FormLayout>
            <FormItem
              top={t('premium_email_title')}
              bottom={t('premium_email_description')}
            >
              <Input
                placeholder={t('premium_email_placeholder')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormItem>
          </FormLayout>
          <FormItem
            bottom={
              isTrialAvailable
                ? `Подписка переодическая, ее можно отменить в любой момент. Следующее списание будет через 3 дня за ${premiumPrice}₽.`
                : `Подписка переодическая, ее можно отменить в любой момент. Следующее списание будет через 1 месяц на ${premiumPrice}₽.`
            }
          ></FormItem>
          <FormItem>
            <Button size="l" stretched mode="secondary" onClick={handleBuy}>
              Чек не нужен
            </Button>
          </FormItem>
          <MainButton
            style="confirm"
            onClick={handleBuy}
            text={t('premium_email_submit')}
          />
        </>
      );
    }
  }

  return (
    <>
      {window.isVK && (
        <PanelHeader before={<PanelHeaderBack onClick={() => goBack()} />} />
      )}
      <ScrollView>{renderContent()}</ScrollView>
    </>
  );
};
