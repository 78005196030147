import s from './App.module.sass';

import React, { useCallback, useEffect, useState } from 'react';
import { useRoute, useRouter } from 'react-router5';
import {
  AppRoot,
  ConfigProvider,
  Platform,
  Placeholder,
  Spinner,
  Root,
} from '@vkontakte/vkui';
import { useDispatch, useSelector } from 'react-redux';

import {
  CHAT,
  DEBUG,
  EDIT,
  FILTERS,
  FRIENDS,
  HOME,
  INVITE,
  JOIN,
  LIKES_LIMIT,
  MATCH,
  MODER,
  PAYWALL,
  PREMIUM,
  PREMIUM_INFO,
  PROFILE_CARD,
  SETTINGS,
} from '$shared/constants/pages';
import { createdSelector, mainActions, userIdSelector } from '$store/main';
import { LottieIcon, Toast } from '$shared/components';
import {
  Chat,
  Debug,
  Edit,
  Filters,
  Friends,
  Home,
  Invite,
  Join,
  LikesLimit,
  Match,
  Moder,
  Paywall,
  Premium,
  PremiumInfo,
  ProfileCard,
  Settings,
} from '$core';
import WebApp from '@twa-dev/sdk';
import { useTranslator } from '$hooks';
import { MainButton } from '$uikit';
import bridge, { EAdsFormats } from '@vkontakte/vk-bridge';
import { Api } from '$api';

const checkExpand = (event: any) => {
  if (!event || !event.isStateStable) {
    return;
  }

  if (!WebApp.isExpanded) {
    WebApp.expand();
  }
};

const App = () => {
  const dispatch = useDispatch();
  const { route } = useRoute();
  const userId = useSelector(userIdSelector);
  const isCreated = useSelector(createdSelector);
  const router = useRouter();
  const t = useTranslator();
  const [vkMessagesRequest, setVkMessagesRequest] = useState(false);

  useEffect(() => {
    if (window.vkAdsTarget) {
      Api.post('/account/start/track', { target: window.vkAdsTarget }).catch(() => {});
    }
  }, [window.vkAdsTarget]);

  const handleVkMessagesRequest = useCallback(() => {
    bridge
      .send('VKWebAppAllowMessagesFromGroup', {
        group_id: 160479731,
        key: 'nuzzle',
      })
      .then((data) => {
        if (!data.result) {
          setVkMessagesRequest(true);
        } else {
          setVkMessagesRequest(false);
        }
      })
      .catch(() => {
        setVkMessagesRequest(true);
      });
  }, []);

  useEffect(() => {
    WebApp.offEvent('viewportChanged', checkExpand);
    WebApp.onEvent('viewportChanged', checkExpand);
  }, []);

  useEffect(() => {
    WebApp.expand();
    //WebApp.enableClosingConfirmation();
    WebApp.setBackgroundColor('bg_color');
    WebApp.setHeaderColor('bg_color');

    if (window.isVK) {
      bridge.send('VKWebAppCheckNativeAds', { ad_format: EAdsFormats.INTERSTITIAL });
    }

    dispatch(mainActions.init());
  }, []);

  useEffect(() => {
    if (window.isVK && isCreated) {
      handleVkMessagesRequest();
    }
  }, [isCreated]);

  const handleCreate = useCallback(() => {
    if (window.isVK) {
      bridge
        .send('VKWebAppGetUserInfo')
        .then((data) => {
          window.initUserData = {
            name: `${data.first_name} ${data.last_name}`.trim(),
            sex: data.sex,
            bDate: '',
          };
          router.navigate(JOIN, { setup: 1 });
        })
        .catch(() => {
          router.navigate(JOIN, { setup: 1 });
        });
    } else {
      const user = WebApp.initDataUnsafe.user;
      window.initUserData = {
        name: `${user?.first_name ?? ''} ${user?.last_name ?? ''}`.trim(),
        sex: 0,
        bDate: '',
      };
      router.navigate(JOIN, { setup: 1 });
    }
  }, []);

  if (!userId) {
    return (
      <ConfigProvider
        appearance={Telegram.WebApp.colorScheme || 'dark'}
        platform={Platform.IOS}
        transitionMotionEnabled={false}
      >
        <AppRoot>
          <div className={s.page} id="pageContent">
            <Placeholder stretched>
              <Spinner />
            </Placeholder>
          </div>
        </AppRoot>
      </ConfigProvider>
    );
  }

  let routeName = route.name;
  if (!isCreated) {
    routeName = JOIN;
  }

  // if (!isCreated && route.name != JOIN) {
  //   return (
  //     <ConfigProvider
  //       appearance={Telegram.WebApp.colorScheme || 'dark'}
  //       platform={Platform.IOS}
  //       transitionMotionEnabled={false}
  //     >
  //       <AppRoot>
  //         <div className={s.page} id="pageContent">
  //           <div className={s.onboardingWrap}>
  //             <Placeholder
  //               stretched
  //               header={t('welcome_title')}
  //               icon={<LottieIcon name="egg" />}
  //             >
  //               {t('welcome_text')}
  //             </Placeholder>
  //             <div className={s.onboardingWarning}>
  //               {t(window.isVK ? 'welcome_warning_vk' : 'welcome_warning')}
  //             </div>
  //           </div>
  //           <MainButton onClick={handleCreate} text={t('welcome_btn')} />
  //         </div>
  //       </AppRoot>
  //     </ConfigProvider>
  //   );
  // }

  if (window.isVK && vkMessagesRequest && isCreated) {
    return (
      <ConfigProvider
        appearance={Telegram.WebApp.colorScheme || 'dark'}
        platform={Platform.IOS}
        transitionMotionEnabled={false}
      >
        <AppRoot>
          <div className={s.page} id="pageContent">
            <div className={s.onboardingWrap}>
              <Placeholder
                stretched
                header="Разрешите отправку сообщений"
                icon={<LottieIcon name="access" loop />}
              >
                Чтобы мы могли рассказать вам о новых лайках и сообщениях.
              </Placeholder>
            </div>
            <MainButton onClick={handleVkMessagesRequest} text="Разрешить сообщения" />
          </div>
        </AppRoot>
      </ConfigProvider>
    );
  }

  function renderPage() {
    switch (routeName) {
      case HOME:
        return <Home />;
      case SETTINGS:
        return <Settings />;
      case FILTERS:
        return <Filters />;
      case PROFILE_CARD:
        return <ProfileCard />;
      case MATCH:
        return <Match />;
      case DEBUG:
        return <Debug />;
      case EDIT:
        return <Edit />;
      case JOIN:
        return <Join />;
      case CHAT:
        return <Chat />;
      case PREMIUM:
        return <Premium />;
      case PREMIUM_INFO:
        return <PremiumInfo />;
      case LIKES_LIMIT:
        return <LikesLimit />;
      case PAYWALL:
        return <Paywall />;
      case INVITE:
        return <Invite />;
      case FRIENDS:
        return <Friends />;
      case MODER:
        return <Moder />;
      default:
        return null;
    }
  }

  return (
    <ConfigProvider
      appearance={Telegram.WebApp.colorScheme || 'dark'}
      platform={Platform.IOS}
      transitionMotionEnabled={false}
    >
      <div className={s.page}>{renderPage()}</div>
      <Toast />
    </ConfigProvider>
  );
};

export default App;
